<template>
  <div class="cost">
    <Header back="返回" title="高新产品" index="首页" titleOne="科创模块" titleTwo="高新产品" beforeTitle="技术指标" />
    <div class="content">
      <h1>技术指标</h1>
      <el-form
        :rules="formRule"
        ref="formRule"
        :model="form"
        :label-position="labelPosition"
        label-width="80px"
      >
        <el-form-item label="PS编号">
          <el-input v-model="form.ps_code"></el-input>
        </el-form-item>
        <el-form-item label="产品（服务）名称">
          <el-input v-model="form.ps_name"></el-input>
        </el-form-item>
        <div class="clear"></div>
        <el-form-item label="技术领域">
          <el-select v-model="form.technology_one" placeholder="请选择" @change="getTechnology_two">
            <el-option v-for="item in one" :key="item.id" :label="item.title" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="form.technology_two" placeholder="请选择" @change="getTechnology_three">
            <el-option v-for="item in two" :key="item.id" :label="item.title" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="form.technology_three" placeholder="请选择">
            <el-option v-for="item in three" :key="item.id" :label="item.title" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <div class="clear"></div>
        <el-form-item label="关键技术" style="width: 100%">
          <el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="form.key_technology"></el-input>
        </el-form-item>
        <el-form-item label="主要技术指标" style="width: 100%">
          <el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="form.kdj"></el-input>
        </el-form-item>
      </el-form>
      <div class="foot_TJ">
        <el-button type="primary" :loading="btnLoading" @click="onSubmit">确定</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      btnLoading: false,//提交按钮动画
      labelPosition: "top",
      form: {
        ps_code: "",
        ps_name: "",
        technology_one: "",
        technology_two: "",
        technology_three: "",
        key_technology: "",
        kdj: "",
        com_id: "",
        id: "",
      },
      one: [],
      two: [],
      three: [],
      formRule: {},
    };
  },
  watch: {
    "form.technology_one": {
      handler (val, oval) {
        this.getTechnology_two(val);
      },
      deep: true,
    },
    "form.technology_two": {
      handler (val, oval) {
        this.getTechnology_three(val);
      },
      deep: true,
    },
  },
  created () {
    this.getTechnology_one();
  },
  computed: {
    meber_id () {
      return this.$route.query.id;
    },
  },
  mounted () {
    if (this.meber_id) {
      this.axios
        .get("/api/ps/view", {
          params: {
            id: this.meber_id,
          },
        })
        .then((res) => {
          this.form.ps_code = res.data.ps_code;
          this.form.ps_name = res.data.ps_name;
          this.form.technology_one = res.data.technology_one;
          this.form.technology_two = res.data.technology_two;
          this.form.technology_three = res.data.technology_three;
          this.form.key_technology = res.data.key_technology;
          this.form.kdj = res.data.kdj;
          this.form.com_id = res.data.com_id;
          this.form.id = this.meber_id;
          console.log(res);
        });
    }
  },
  methods: {
    getTechnology_one () {
      this.axios.get("/api/tech/get_first_level").then((res) => {
        this.one = res.data;
      });
    },
    getTechnology_two (id) {
      this.axios
        .get("/api/tech/get_children_list", {
          params: {
            id: id,
          },
        })
        .then((res) => {
          this.two = res.data;
        });
    },
    getTechnology_three (id) {
      this.axios
        .get("/api/tech/get_children_list", {
          params: {
            id: id,
          },
        })
        .then((res) => {
          this.three = res.data;
        });
    },
    onSubmit () {
      this.com_id = this.form.com_id;
      this.id = this.form.id;
      console.log(this.form);
      this.$refs.formRule.validate((vaild) => {
        if (vaild) {
          this.btnLoading = true
          this.axios
            .post("/api/ps/store", this.form)
            .then((res) => {
              this.$message({
                type: "success",
                message: "保存成功!",
              });
              this.$router.push("/ps/index");
              this.btnLoading = false
            })
            .catch((res) => {
              console.log("err:", res);
              this.btnLoading = false
            });
        } else {
          this.$message.error("");
        }
      });
    },
  },
};
</script>

<style scoped="scoped">
::v-deep.el-form-item {
  width: 23%;
  display: inline-block;
  margin-right: 2%;
}

.el-select {
  width: 100%;
}

.ImportTop span {
  color: #a6abc7;
}
</style>
